import { navigate } from 'gatsby';
import React from 'react';
import { handleAuthentication } from '../../../utils/auth';

const Callback = () => {

    React.useEffect(() => {
        handleAuthentication();
        navigate('/admin/pages');
    }, []);

    return <p>Loading...</p>
}

export default Callback;